import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { withNamespaces } from 'react-i18next'
import shortid from 'shortid'
import { Container, Row, Col } from 'react-grid-system'
import { Section, ImageBasic, SliderBasic } from 'inno-components'

import Classes from '../../styles/classes'

const responsive = [
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
]
const styles = StyleSheet.create({
  image: {
    height: 'auto',
    maxWidth: '8em',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2em',
  },
  titre: {
    margin: '1em 0em 0.2em 0em',
  },
  description: {
    fontStyle: 'italic',
  },
})
const ItemComponent = item => (
  <ImageBasic src={item.src} className={css(styles.image)} />
)
class References extends Component {
  state = {
    posts: null,
  }
  componentDidMount() {}
  render() {
    const { t } = this.props
    const images = [
      {
        titre: 'AD Delhaize & Proxy Delhaize',
        description: t('references.depuis') + ' 2011',
        src:
          'http://www.screeningmedia.com/new-website/img/testimonials-04.png',
      },
      {
        titre: 'Carrefour Market & Carrefour Express',
        description: t('references.depuis') + ' 2012',
        src:
          'http://www.screeningmedia.com/new-website/img/testimonials-05.png',
      },
      {
        titre: 'Intermarché',
        description: t('references.depuis') + ' 2011',
        src:
          'http://www.screeningmedia.com/new-website/img/testimonials-06.png',
      },
    ]

    const items = images.map(image => {
      let Content = <ImageBasic src={image.src} className={css(styles.image)} />
      return (
        <div key={image.src}>
          <div className={css(styles.item)}>
            <h4 className={css(styles.titre)}>{image.titre}</h4>
            <p className={css(styles.description)}>{image.description}</p>
          </div>
        </div>
      )
    })
    return (
      <Section className={css(Classes.section)}>
        <Container>
          <Row>
            <Col>
              <h2 className={css(Classes.title)}>{t('references.titre')}</h2>
              <p>{t('references.paragraphes.0')}</p>
              <p> {t('references.paragraphes.1')}</p>
              <SliderBasic
                slidesToShow={1}
                autoplaySpeed={2000}
                slidesToScroll={1}
                autoplay
                responsive={responsive}
              >
                {items}
              </SliderBasic>
            </Col>
          </Row>
        </Container>
      </Section>
    )
  }
}

export default withNamespaces('home')(References)
