import React, { Component } from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import { withNamespaces } from 'react-i18next'
import Scroll from 'react-scroll'
import Helmet from 'react-helmet'

import PrimaryLayout from '../layouts/PrimaryLayout'

import Accueil from './Accueil'
import VosCampagnes from './VosCampagnes'
import Contact from './Contact'
import NotreEntreprise from './NotreEntreprise'
import References from './References'

class IndexPage extends Component {
  componentDidMount() {
    Scroll.scrollSpy.update()

    let hash = window.location.hash
    hash = hash.replace('#', '')

    if (hash && hash != '') {
      console.log('hash : ' + hash)
      Scroll.scroller.scrollTo(hash)
    }
  }
  render() {
    const { t } = this.props
    return (
      <PrimaryLayout>
        <Helmet
          title={t('head.title')}
          meta={[{ name: 'description', content: t('head.description') }]}
        />
        <Accueil />
        <Scroll.Element name="notre-entreprise" id="notre-entreprise">
          <NotreEntreprise />
        </Scroll.Element>
        <Scroll.Element name="vos-campagnes" id="vos-campagnes">
          <VosCampagnes />
        </Scroll.Element>
        <Scroll.Element name="contact" id="contact">
          <Contact />
        </Scroll.Element>
        <Scroll.Element name="references" id="references">
          <References />
        </Scroll.Element>
      </PrimaryLayout>
    )
  }
}

export default withNamespaces('home')(IndexPage)
