import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { withNamespaces } from 'react-i18next'
import { Section, Paper, Icons, ImageBasic } from 'inno-components'

import { Container, Row, Col } from 'react-grid-system'

import Classes from '../../styles/classes'
import Colors from '../../styles/colors'
import Config from '../../Config'

const styles = StyleSheet.create({
  titleWhite: {
    color: '#fff',
  },
  section: {
    backgroundcolor: '#E5EAEF',
  },
  sectionBorders: {
    borderBottomLeftRadius: '50% 10%',
    borderBottomRightRadius: '50% 10%',
  },
  paper: {
    padding: '2em',
  },
  sectionContainer: {
    backgroundColor: '#cb2c32',
  },
  iconContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    height: 'auto',
    width: '100%',
    maxWidth: '200px',
  },
  section: {
    backgroundColor: '#F5F5F5',
  },
  paragraphWhite: {
    color: '#fff',
    borderColor: '#fff',
    marginBottom: '3em',
  },
  imageContainer: {
    display: 'block',
    width: '50%',
    maxWidth: '50%',
  },
  avantagesContainer: {
    backgroundColor: Config.colors.primary,
    width: '50%',
    display: 'block',
    padding: '2em',
    textAlign: 'center',
  },
  landing: {
    fontSize: '2.2em',
    display: 'block',
    textAlign: 'center',
  },
  sectionAvantages: {
    backgroundColor: '#cb2c32',
  },
  sectionPartenaires: {
    backgroundColor: '#fff',
  },
})

class NotreEntreprise extends Component {
  state = {
    posts: null,
  }
  componentDidMount() {}
  render() {
    const { t } = this.props
    return (
      <div>
        <Section className={css(Classes.section)}>
          <Container>
            <Row>
              <Col md={12}>
                <p className={css(styles.landing)}>{t('expertise.accroche')}</p>
              </Col>
              <Col md={12}>
                <h2 className={css(Classes.title)}>{t('expertise.titre')}</h2>
              </Col>
              <Col md={8}>
                <h3>{t('expertise.affichage.titre')}</h3>
                <p
                  className={css(Classes.paragraph, Classes.paragraphBordered)}
                >
                  {t('expertise.affichage.parapgraphe')}
                </p>
              </Col>
              <Col md={4}>
                <div className={css(styles.iconContainer)}>
                  <ImageBasic
                    src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/screening-media/images/noun_coin_1973322.svg"
                    className={css(styles.icon)}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className={css(Classes.section, styles.section)}>
          <Container>
            <Row>
              <Col md={4}>
                <div className={css(styles.iconContainer)}>
                  <ImageBasic
                    src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/screening-media/images/noun_television_ads_1906387.svg"
                    className={css(styles.icon)}
                  />
                </div>
              </Col>
              <Col md={8}>
                <h3>{t('expertise.publicite.titre')}</h3>
                <p
                  className={css(Classes.paragraph, Classes.paragraphBordered)}
                >
                  {t('expertise.publicite.parapgraphe')}
                </p>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className={css(styles.sectionContainer)}>
          <Section
            className={css(
              Classes.section,
              styles.sectionBorders,
              styles.sectionPartenaires
            )}
          >
            <Container>
              <Row>
                <Col md={8}>
                  <h3>{t('expertise.partenaires.titre')}</h3>
                  <p
                    className={css(
                      Classes.paragraph,
                      Classes.paragraphBordered
                    )}
                  >
                    {t('expertise.partenaires.parapgraphe')}
                  </p>
                </Col>
                <Col md={4}>
                  <div className={css(styles.iconContainer)}>
                    <ImageBasic
                      src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/screening-media/images/noun_networking_1892014.svg"
                      className={css(styles.icon)}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </Section>
        </div>
        <Section className={css(Classes.section, styles.sectionAvantages)}>
          <Container>
            <Row>
              <Col md={12}>
                <h2 className={css(Classes.title, styles.titleWhite)}>
                  {t('avantages.titre')}
                </h2>
              </Col>
              <Col md={8}>
                <p
                  className={css(
                    Classes.paragraph,
                    Classes.paragraphBordered,
                    styles.paragraphWhite
                  )}
                >
                  <h3 className={css(styles.titleWhite)}>
                    {t('avantages.sections.0.titre')}
                  </h3>
                  {t('avantages.sections.0.description')}
                </p>

                <p
                  className={css(
                    Classes.paragraph,
                    Classes.paragraphBordered,
                    styles.paragraphWhite
                  )}
                >
                  <h3 className={css(styles.titleWhite)}>
                    {t('avantages.sections.1.titre')}
                  </h3>
                  {t('avantages.sections.1.description')}
                </p>
                <p
                  className={css(
                    Classes.paragraph,
                    Classes.paragraphBordered,
                    styles.paragraphWhite
                  )}
                >
                  {t('avantages.sections.2.description')}
                </p>
              </Col>
            </Row>
          </Container>
        </Section>
      </div>
    )
  }
}

export default withNamespaces('home')(NotreEntreprise)
