import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { withNamespaces } from 'react-i18next'
import { Section, Button } from 'inno-components'
import { Container, Row, Col } from 'react-grid-system'
import Classes from '../../styles/classes'
import Link from '../Link'
import Config from '../../Config'
import Colors from '../../styles/colors'
import pagesInfos from '../../config/i18n/pagesInfos'
const styles = StyleSheet.create({
  landing: {
    fontSize: '2em',
    display: 'block',
    textAlign: 'center',
  },
  paragraph: {
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    margin: '2em 0',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    padding: '1em',
    backgroundColor: '#fff',
  },
})

class VosCampagnes extends Component {
  state = {
    posts: null,
  }
  componentDidMount() {}
  render() {
    const { t } = this.props
    return (
      <Section className={css(Classes.section)}>
        <Container>
          <Row>
            <Col>
              <p className={css(styles.landing)}>{t('campagnes.accroche')}</p>
              <h2 className={css(Classes.title)}>
                <Link page={pagesInfos.ComponentCampagneDeNotoriete}>
                  {t('campagnes.notoriete.titre')}
                </Link>
              </h2>
              <p className={css(styles.paragraph, Classes.paragraph)}>
                {t('campagnes.notoriete.description')}
              </p>
              <div className={css(styles.buttonContainer)}>
                <Link page={pagesInfos.ComponentCampagneDeNotoriete}>
                  <Button className={css(styles.button)}>
                    {t('campagnes.notoriete.button')}
                  </Button>
                </Link>
              </div>
              <h2 className={css(Classes.title)}>
                <Link page={pagesInfos.ComponentCampagneDeCommunication}>
                  {t('campagnes.communication.titre')}
                </Link>
              </h2>
              <p className={css(styles.paragraph, Classes.paragraph)}>
                {t('campagnes.communication.description')}
              </p>
              <div className={css(styles.buttonContainer)}>
                <Link page={pagesInfos.ComponentCampagneDeCommunication}>
                  <Button className={css(styles.button)}>
                    {t('campagnes.communication.button')}
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    )
  }
}

export default withNamespaces('home')(VosCampagnes)
