import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { scroller } from 'react-scroll'
import { withNamespaces } from 'react-i18next'
import { fadeInRight } from 'react-animations'
import { Section, Button, Breakpoints } from 'inno-components'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-grid-system'
import Img from 'gatsby-image'
import Config from '../../Config'
import Colors from '../../styles/colors'
import Link from '../Link'
import pagesInfos from '../../config/i18n/pagesInfos'

const styles = StyleSheet.create({
  home: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
  title: {
    fontSize: '2.3em',
    fontWeight: 800,
    textTransform: 'uppercase',
    display: 'inline',
    margin: 0,
    color: '#fff',
    padding: '0.1em 0.3em',
    backgroundColor: Config.colors.primary,
    [Breakpoints.small]: {
      fontSize: '2.4em',
    },
  },
  textContainer: {
    display: 'block',
    textAlign: 'center',
    margin: '2.5em 0em',
    animationName: fadeInRight,
    animationDuration: '1s',
  },
  caption: {
    margin: '0 3em',
    color: '#fff',
    fontSize: '2em',
    [Breakpoints.small]: {
      margin: '0.5em 0 0 0',
    },
  },
  captionSmall: {
    fontSize: '2em',
    fontWeight: 600,
    [Breakpoints.small]: {
      fontSize: '1.2em',
    },
  },
  menuContainer: {
    display: 'block',
    minHeight: '10vh',
  },
  chevronContainer: {
    marginBottom: '2em',
    [Breakpoints.small]: {
      marginTop: '1em',
    },
  },
  chevronButton: {
    backgroundColor: Config.colors.primary,
    lineHeight: '2.5em',
    boxShadow: 'none',
  },
  chevronButtonIcon: {
    color: '#fff',
    fontSize: '2.5em',
  },
  top: {
    height: '10vh',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(/static/bg_top.svg)',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    margin: '0.5em',
    padding: '1em',
    width: '100%',
    backgroundColor: '#fff',
    fontWeight: 'bold',
  },
  buttonPrimary: {
    color: '#fff',
    backgroundColor: Config.colors.primary,
  },
  svgContainer: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    paddingBottom: '100%',
    verticalAlign: 'middle',
    overflow: 'hidden',
  },
  svg: { display: 'block', width: '100%', height: '100%' },
  section: {
    position: 'relative',
  },
  background: {
    position: 'absolute',
    zIndex: -1,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    borderBottomLeftRadius: '50% 10%',
    borderBottomRightRadius: '50% 10%',
  },
  sectionContainer: { backgroundColor: '#FAFAFA' },
})

const Accueil = ({ t }) => (
  <StaticQuery
    query={graphql`
      query AccueilImageQuery {
        bg: file(relativePath: { eq: "home/main.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 3500, maxHeight: 2333) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Section className={css(styles.section)} minHeight="100vh">
          <Img
            fluid={data.bg.childImageSharp.fluid}
            className={css(styles.background)}
          />
          <Container>
            <div className={css(styles.home)}>
              <Row>
                <h1 className={css(styles.title)}>{t('accueil.titre')}</h1>
              </Row>
              <Row style={{ width: '100%', marginTop: '2em' }}>
                <Col md={4}>
                  <Link page={pagesInfos.ComponentCampagneDeNotoriete}>
                    <Button className={css(styles.button)}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('accueil.button1'),
                        }}
                      />
                    </Button>
                  </Link>
                </Col>
                <Col md={4}>
                  <Link page={pagesInfos.ComponentCampagneDeCommunication}>
                    <Button className={css(styles.button)}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('accueil.button2'),
                        }}
                      />
                    </Button>
                  </Link>
                </Col>
                <Col md={4}>
                  <Link page={pagesInfos.ComponentInstalltionEcrans}>
                    <Button className={css(styles.button)}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('accueil.button3'),
                        }}
                      />
                    </Button>
                  </Link>
                </Col>
              </Row>
            </div>
          </Container>
        </Section>
      )
    }}
  />
)

export default withNamespaces('home')(Accueil)
