import React, { Component } from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import { Scroll } from 'inno-components'

import PrimaryLayout from '../components/layouts/PrimaryLayout'

import IndexPage from '../components/home/IndexPage'
import VosCampagnes from '../components/home/VosCampagnes'
import Contact from '../components/home/Contact'
import NotreEntreprise from '../components/home/NotreEntreprise'
import References from '../components/home/References'

import SetLanguageFromPage from '../config/i18n/SetLanguageFromPage'

class Index extends Component {
  componentDidMount() {
    Scroll.scrollSpy.update()

    let hash = window.location.hash
    hash = hash.replace('#', '')

    if (hash && hash != '') {
      console.log('hash : ' + hash)
      Scroll.scroller.scrollTo(hash)
    }
  }
  render() {
    return (
      <IndexPage/>
    )
  }
}

export default SetLanguageFromPage()(Index)
